import { $body } from "../utils/globals.js"
import 'shikwasa/dist/style.css'
import { Player } from 'shikwasa'
import { metaSet } from './meta.js';

if ($body.hasClass('page-podcast')) {
    let podcastTitle = $("a.btn--play").data('title')
    let podcastAuthor = $("a.btn--play").data('author')
    let podcastCoverSrc = $("a.btn--play").data('coversrc')
    let podcastAudioSrc = $("a.btn--play").data('audiosrc')

    var activeEpisode = $(".article-podcast.is-active")
    if (activeEpisode.length) {
        
        podcastTitle = activeEpisode.find("a.btn--play").data('title')
        podcastAuthor = activeEpisode.find("a.btn--play").data('author')
        podcastCoverSrc = activeEpisode.find("a.btn--play").data('coversrc')
        podcastAudioSrc = activeEpisode.find("a.btn--play").data('audiosrc')
    }
    
    const player = new Player({
        container: () => document.querySelector('section.audio__player'),
        audio: {
            title: podcastTitle,
            artist: podcastAuthor,
            cover: podcastCoverSrc,
            src: podcastAudioSrc,
            themeColor: '#000000',
            speedOptions: [0.5, 0.75, 1.0, 1.23, 1.5, 1.75, 2.0]
        }
    })

    $("a.btn--play").on("click", (e) => {
        e.preventDefault();
        let $this = $(e.currentTarget);
        //Clear previous active episode
        $('a.btn--play.active').removeClass('active')
        $('.article-podcast').removeClass('is-active')

        // Update audio player
        const podcastTitle = $this.data('title')
        const podcastAuthor = $this.data('author')
        const podcastCoverSrc = $this.data('coversrc')
        const podcastAudioSrc = $this.data('audiosrc')
        const podcastAudio = {
            title: podcastTitle,
            artist: podcastAuthor,
            cover: podcastCoverSrc,
            src: podcastAudioSrc,
            themeColor: '#000000',
            speedOptions: [0.5, 0.75, 1.0, 1.23, 1.5, 1.75, 2.0]
        }
        player.update(podcastAudio)
        player.play()

        //Update Play button & episode article
        $this.addClass('active')
        $this.parents('.article-podcast').addClass('is-active')

        //Update URL with episode URL
        let episodeURL = $this.parents('.article-podcast').data('url')
        history.pushState({ episodeURL: episodeURL }, null, episodeURL);

        //Update meta tags with episode SEO
        metaSet($this.parents('.article-podcast').data('id'))
    })
    $("a.read__more").on("click", function () {
        $(this).parents('.article__content').toggleClass('read__more');
        var readmoreText = $(this).find('h2')[0]
        readmoreText.innerHTML == "Read More" ? readmoreText.innerHTML = "Close" : readmoreText.innerHTML = "Read More"
    })
}